import app from 'apprun';
import _, { lang } from '../l10n';

declare let $;
const link = lang === 'en' ? ('https://www.youtube.com/embed/af3rVgITqCs') : ('https://www.youtube.com/embed/bynUkDrDRPU');

const Errors = ({ msg, detail }) => {
  return (
    <div class="alert alert-danger mt3" role="alert">
      <h5 class="text-uppercase mt0 mb3 fw700">
        <i class="fas fa-exclamation-triangle mr1" />
        {_('The following errors have occurred')}
      </h5>
      <p class="text-uppercase fw700">{_(msg)}</p>
      <ul class="fa-ul fa-ul-circle no-indent">
        <li>
          <span class="fa-li">
            <i class="fas fa-circle" />
          </span>
          {_(detail)}
        </li>
      </ul>
    </div>
  );
};

export const ResetPasswordView = ({ state, mainComponent }) => {
  return (
    <div class="section section-padding-lg">
      <div class="container">
        <div class="row">
          <div class="col-sm-18">
            <div
              class="row"
              id="passwordChangeSuccessful"
              style={{ display: state.passwordChanged ? 'block' : 'none', 'padding-right': '17px' }}>
              {_('Your password has been changed successfully.')}
              <button
                onclick={(e) => {
                  mainComponent.run('sign-in-loading', e);
                  mainComponent.run('#sign-in', e);
                }}
                value=""
              />
              <button
                class="btn btn-rlp btn-block"
                disabled={state.isLoading}
                onclick={(e) => {
                  mainComponent.run('sign-in-loading', e);
                  mainComponent.run('resetpassword-sign-in', e);
                }}>
                <i class="far fa-arrow-alt-circle-right text-uppercase mr1" />
                {_('Login')}
              </button>
            </div>

            <div
              class="row"
              style={{ display: !state.passwordChanged ? 'block' : 'none', 'padding-right': '17px' }}>
              <br />
              <div class="row">
                {state.error.msg ? <Errors msg={state.error.msg} detail={state.error.detail} /> : ''}
                <div class="col-sm-8">
                  {/* <div>
                    {_('Password is a required field. Remember that the password must have at least 8 characters and it should not exceed 40 characters.')}
                  </div> */}
                  <br/>
                  <div class="form-group" >
                    <label for="email">{_('Password')}</label>
                    <input
                      type="password"
                      class="form-control"
                      id="password"
                      name="password"
                      value={state.resetPasswordForm.password}
                      oninput={() => mainComponent.run('new-pwd-changed')}
                      onfocus={() => mainComponent.run('new-pwd-changed')}
                      // onblur={e => mainComponent.run('validatePassword', e)}
                    />
                  </div>
                  <div class="form-group">
                    <label for="email">{_('Confirm Password')}</label>
                    <input type="password" class="form-control" id="confirmPassword" name="confirmPassword" oninput={() => mainComponent.run('new-pwd-changed')}/>
                  </div>
                </div>
                <div class="form-group col-sm-16" >
                  <ul>
                    <li id="rule1"><i id="rule1Icon" class="fas" style="font-size:20px;" /> {_('Password must have at least 8 characters and it must not exceed 40 characters.')}</li>
                    <li id="rule2"><i id="rule2Icon" class="fas" style="font-size:20px;" /> {_('Password must contain at least one uppercase letter.')}</li>
                    <li id="rule3"><i id="rule3Icon" class="fas" style="font-size:20px;" /> {_('Password must contain at least one lowercase letter.')}</li>
                    <li id="rule4"><i id="rule4Icon" class="fas" style="font-size:20px;" /> {_('Password must contain at least one digit.')}</li>
                    <li id="rule5"><i id="rule5Icon" class="fas" style="font-size:20px;" /> {_('Password must contain at least one special character.')}</li>
                    <li id="rule6"><i id="rule6Icon" class="fas" style="font-size:20px;" /> {_('Confirm Password must be same as New Password.')}</li>
                  </ul>
                </div>
              </div>
              <div class="row mt4 mb4">
                <div class="col-lg-14">
                  <button
                    onclick={() =>
                      mainComponent.run('changePassword', $('#password').val(), $('#confirmPassword').val())
                    }
                    class="btn btn-rlp btn-block pull-right-lg">
                    <i class="fas fa-question-circle text-uppercase mr1" />
                    {_('Change Password')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export const LoginView = ({ state, mainComponent, textMessage }) => {
  return (
    <div class="section section-padding-lg">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="row">
              <br />
              <div class="col-sm-18">
                {state.error.msg ? <Errors msg={state.error.msg} detail={state.error.detail} /> : ''}
                <form
                  onsubmit={(e) => {
                    mainComponent.run('sign-in-loading', e);
                    mainComponent.run('sign-in', e);
                  }}>
                  <div class="form-group">
                    <label for="email">
                      {_('Email Address')} / {_('User Name')}{' '}
                    </label>
                    <input type="text" class="form-control" id="email" name="email" autocomplete="username" />
                  </div>
                  <div class="form-group">
                    <label for="email">{_('Password')}</label>
                    <input
                      type="password"
                      class="form-control"
                      id="password"
                      name="password"
                      autocomplete="current-password"
                    />
                  </div>
                  <div class="row mt4 mb4">
                    <div class="col-lg-12 mb2">
                      <button type="submit" class="btn btn-rlp btn-block" disabled={state.isLoading}>
                        <i class="far fa-arrow-alt-circle-right text-uppercase mr1" />
                        {_('Login')}
                      </button>
                    </div>
                    <div class="col-lg-12">
                      <button
                        onclick={e => mainComponent.run('toggleFlag', 'state.forgotPasswordStep1', e)}
                        class="btn btn-rlp btn-rlp-inverse btn-block pull-right-lg">
                        <i class="fas fa-question-circle text-uppercase mr1" />
                        {_('Forgot Password')}
                      </button>
                    </div>
                  </div>
                </form>
                <div />
              </div>
            </div>
          </div>
          <div class="col-sm-12">
            <h3 class="mt0">{_('Work Faster.Work Smarter.rlpNetwork.')}</h3>
            <div class="embed-responsive embed-responsive-16by9">
              <iframe src={link} frameborder="0" allowfullscreen="" />
            </div>
            <div>{textMessage}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
