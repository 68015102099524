/* eslint-disable @typescript-eslint/no-explicit-any */
import app from 'apprun';
import jwt_decode, { JwtPayload }  from 'jwt-decode';

const defaultBasePath = window['defaultBasePath'] || '/api';

let access_token: string = (window && window.localStorage && window.localStorage.getItem('jwt')) || '';
// let access_token: string = getCookie('jwt');
export function getToken() {
  return access_token;
}
export function setToken(token: string) {
  access_token = token;
  // document.cookie = 'jwt=' + token;
  if (!window.localStorage) {return;}
  if (token) {window.localStorage.setItem('jwt', token);} else {window.localStorage.removeItem('jwt');}
}

const NProgress = window['NProgress'];
async function fetchAsync(method: 'GET' | 'POST' | 'DELETE' | 'PUT', url: string, body?: any) {
  NProgress?.set(0.4);
  const headers = access_token ? { Authorization: `Bearer ${access_token}` } : {};
  headers['Content-Type'] = 'application/json; charset=utf-8';
  url = `${defaultBasePath}${url}`;
  const response = await window['fetch'](url, {
    method,
    headers,
    body: body && JSON.stringify(body)
  });
  NProgress?.done();
  if (response.status === 401) {
    app.run('show-message', 'Authentication failed ... 🚫🤚😦', 401);
    app.run('#login', response);
    return;
  }
  if (!response.ok) {
    app.run('show-message', 'Calling the server ... 💥👎😓', 500);
    throw response;
  }

  return response.json();
}

async function fetchFileAsync(method: 'GET' | 'POST' | 'DELETE' | 'PUT', url: string, body?: any) {
  NProgress?.set(0.4);
  const headers = access_token ? { Authorization: `Bearer ${access_token}` } : {};
  headers['enctype'] = 'multipart/form-data';
  url = `${defaultBasePath}${url}`;
  const response = await window['fetch'](url, {
    method,
    headers,
    body: body
  });
  NProgress?.done();
  if (response.status === 401) {
    app.run('show-message', 'Authentication failed ... 🚫🤚😦', 401);
    app.run('#login', response);
    return;
  }
  if (!response.ok) {
    throw response.statusText;
  }

  return response.text();
}

async function check_jwt() {
  try {
    const access_token_expiry = jwt_decode<JwtPayload>(access_token).exp;
    if (Date.now() / 1000 > access_token_expiry) {
      const newToken = await fetchAsync('GET', '/users/refreshJWT');
      setToken(newToken);
    }
    return true;
  } catch {
    setToken(null);
  }
}

export async function get<T = any>(url: string, auth = true): Promise<T> {
  const ok = !auth || await check_jwt();
  if (ok) {return fetchAsync('GET', url);}
}

export async function post<T = any>(url: string, body: any, auth = true): Promise<T> {
  const ok = !auth || await check_jwt();
  if (ok) {return fetchAsync('POST', url, body);}
}

export function postFile<T = any>(url: string, body?: any): Promise<string | void> {
  return fetchFileAsync('POST', url, body);
}

export function del(url: string, body?: any) {
  return fetchAsync('DELETE', url, body);
}

export function put(url: string, body?: any) {
  return fetchAsync('PUT', url, body);
}

export function toQueryString(obj) {
  const parts = [];
  for (const i in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(i)) {
      parts.push(encodeURIComponent(i) + '=' + encodeURIComponent(obj[i]));
    }
  }
  return parts.join('&');
}

export function serializeObject<T>(form) {
  const obj = {};
  if (typeof form === 'object' && form.nodeName === 'FORM') {
    for (let i = 0; i < form.elements.length; i++) {
      const field = form.elements[i];
      if (
        field.name &&
        field.type !== 'file' &&
        field.type !== 'reset' &&
        field.type !== 'submit' &&
        field.type !== 'button'
      ) {
        if (field.type === 'select-multiple') {
          obj[field.name] = '';
          let tempvalue = '';
          for (let j = 0; j < form.elements[i].options.length; j++) {
            if (field.options[j].selected) {tempvalue += field.options[j].value + ';';}
          }
          if (tempvalue.charAt(tempvalue.length - 1) === ';') {obj[field.name] = tempvalue.substring(0, tempvalue.length - 1);}
        } else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
          obj[field.name] = field.value;
        }
      }
    }
  }

  return obj as T;
}

export function serializeObjectEnhanced<T>(form) {
  const obj = {};
  if (typeof form === 'object' && form.nodeName === 'FORM') {
    for (let i = 0; i < form.elements.length; i++) {
      const field = form.elements[i];
      if (
        field.name &&
        field.type !== 'file' &&
        field.type !== 'reset' &&
        field.type !== 'submit' &&
        field.type !== 'button'
      ) {
        if (field.type === 'select-multiple') {
          obj[field.name] = '';
          let tempvalue = '';
          for (let j = 0; j < form.elements[i].options.length; j++) {
            if (field.options[j].selected) {tempvalue += field.options[j].value + ';';}
          }
          if (tempvalue.charAt(tempvalue.length - 1) === ';') {obj[field.name] = tempvalue.substring(0, tempvalue.length - 1);}
        } else if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
          obj[field.name] = field.value;
        } else if ((field.type === 'checkbox' || field.type === 'radio') && !field.checked) {
          obj[field.name] = '';
        }
      }
    }
  }
  return obj as T;
}
