/* eslint-disable no-irregular-whitespace */
import app, { Component } from 'apprun';
import { auth, serializeObject, users } from '../api';

import _, { lang, setLanguage } from '../l10n';
import { LoginView, ResetPasswordView } from './login-views';

export function getCookie(name: string): string {
  const nameLenPlus = name.length + 1;
  return (
    document.cookie
      .split(';')
      .map(c => c.trim())
      .filter((cookie) => {
        return cookie.substring(0, nameLenPlus) === `${name}=`;
      })
      .map((cookie) => {
        return decodeURIComponent(cookie.substring(nameLenPlus));
      })[0] || null
  );
}

declare let $;

enum Language {
  en = 0,
  fr = 1,
}

interface IState {
  lang: Language;
  error: {
    msg: string;
    detail: string;
  };
  isLoading: boolean;
  redirectUrl: string;
  lightBoxValue: boolean;
  forgotPasswordStep1: boolean;
  forgotPasswordStep2: boolean;
  forgotPasswordStep3: boolean;
  noEmailAddressProvided: boolean;
  recoveryEmail?: string;
  maskedRecoveryEmail?: string;
  validStep2: boolean;
  showResetPasswordView: boolean;
  //passwordMatched: boolean;
  resetPasswordForm: IResetPassword;
  passwordChanged: boolean;
}

interface IResetPassword {
  emailAddress: string;
  hashCode: string;
  password: string;
  confirmPassword: string;
}

export default class LoginComponent extends Component {
  state: IState = {
    lang: window['initialLanguage'] | Language[lang],
    error: { msg: '', detail: '' },
    isLoading: false,
    redirectUrl: '',
    lightBoxValue: false,
    forgotPasswordStep1: false,
    forgotPasswordStep2: false,
    forgotPasswordStep3: false,
    noEmailAddressProvided: false,
    recoveryEmail: '',
    validStep2: false,
    showResetPasswordView: false,
    // passwordMatched : false,
    resetPasswordForm: { emailAddress: '', hashCode: '', password: '', confirmPassword: '' },
    passwordChanged: false,
  };

  view = (state: IState) => {
    let logoImage, logoImageFooter, textMessage, helpContent;
    if (state.lang) {
      //fr
      logoImage = '_html:<img src="/wp-content/themes/rlp/img/logo-rlp-fr-v1.png" alt="Royal LePage rlpNetwork" />';
      logoImageFooter =
        '_html:<br/><br/><img src="/wp-content/themes/rlp/img/logo-rlp-white-fr-v1.png" alt="Royal LePage rlpNetwork" class="my3 pt1"/>';
      textMessage =
        '_html: Accéder aux meilleurs outils de l\'industrie, conçu pour maximiser votre potentiel de revenus. <a href=\'http://www.royallepage.ca/immobilier/fr/join-our-team/\' target=\'_blank\'>  Apprendre davantage</a>.';

      helpContent = ` Si vous avez un compte courriel Royal LePage, votre code d'utilisateur est votre adresse courriel, par exemple, "xxxxx@royallepage.ca".
      Si vous n'avez pas un compte Royal LePage, veuillez contacter votre bureau ou notre Centre d'assistance à la clientèle.
      Si vous avez besoin d'aide, veuillez nous rejoindre par courriel à aide@royallepage.ca ou par téléphone au 1-877-757-4545.  `;
    } else {
      //en
      logoImage = '_html:<img src="/wp-content/themes/rlp/img/logo-rlp-v1.png" alt="Royal LePage rlpNetwork" />';
      logoImageFooter =
        '_html:<br/><br/><img src="/wp-content/themes/rlp/img/logo-rlp-white-v1.png" alt="Royal LePage rlpNetwork" class="my3 pt1"/>';
      textMessage =
        '_html: Access the industry’s best resources: Designed to maximize your earning potential. <a href="http://www.royallepage.ca/joinus" target="_blank">Learn more</a>.';

      helpContent = `If you have an active Royal LePage email account, your username is your address, i.e. xxxxx@royallepage.ca.
        If you do not have a Royal LePage email address, please contact your office or Customer Care to confirm your username.
        If you have any questions or need help, contact us at help@royallepage.ca or call 1-877-757-4545. `;
    }

    return (
      <span>
        <div class="header mb3 header-login">
          <nav class="navbar navbar-brand-nav">
            <div class="container">
              <div class="navbar-header">
                <a class="navbar-left">{logoImage}</a>
              </div>

              <ul class="nav navbar-nav navbar-right hidden-xs">
                <li onclick={() => this.run('lightBoxValue')}>
                  <a href="" style={{ cursor: 'pointer' }}>
                    <i class="fas fa-question-circle mr1" />
                    {_('Help')}
                  </a>
                </li>
                <li>
                  <a href="#" onclick={() => this.run('#set-language')}>
                    {_('Français')}
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>

        <ul class="nav navbar-nav navbar-right visible-xs visible-sms">
          <li class="col-xs-6" onclick={() => this.run('lightBoxValue')}>
            <a> {_('Help')}</a>
          </li>
          <li class="col-xs-6">
            <a href="#" onclick={() => this.run('#set-language')}>
              {_('Français')}
            </a>
          </li>
        </ul>

        <div class="section section-red pt4">
          <div class="container pt4">
            <p class="h2 fw300 pull-left-lg pull-left-md pull-left-sm">
              {'_html:' + _('WELCOME') + '. ' + _('LOG IN TO') + ' ' + _('rlpNetwork<sup style="font-size:12pt">TM</sup>') + '.'}
            </p>
            <br class="visible-xs" />
            <a
              href="https://docs.rlpnetwork.com/systemstatus/systemstatus.html"
              target="_blank"
              class="btn btn-rlp btn-rlp-inverse text-uppercase pull-right-md pull-right-lg">
              <i class="fas fa-info-circle mr1" />
              {_('System Status')}
            </a>
          </div>
        </div>

        <div
          class="modal in"
          id="lightbox"
          tabindex="-1"
          role="dialog"
          style={{ display: state.lightBoxValue ? 'block' : 'none', 'padding-right': '17px' }}>
          <div class="modal-dialog" role="document">
            <div class="modal-content" style={{ height: 'auto' }}>
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" onclick={() => this.run('lightBoxValue')}>
                  <span aria-hidden="true">
                    <h1 class="m0">×</h1>
                  </span>
                </button>
              </div>
              <div class="modal-body modal-body-lightbox">
                <div class="container">
                  <div class="row">
                    <div class="col-sm-16 col-sm-offset-4">
                      <h1>  {_('Help')}</h1>
                      <p>{helpContent}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal in"
          id="forgotPasswordStep1"
          tabindex="-1"
          role="dialog"
          style={{ display: state.forgotPasswordStep1 ? 'block' : 'none', 'padding-right': '17px' }}>
          <div class="modal-dialog" role="document">
            <div class="modal-content" style={{ height: 'auto' }}>
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">
                    <h1 class="m0" onclick={e => this.run('toggleFlag', 'state.forgotPasswordStep1', e)}>
                      ×
                    </h1>
                  </span>
                </button>
              </div>
              <div class="modal-body modal-body-lightbox">
                <div class="container">
                  <div class="row">
                    <div class="col-sm-16 col-sm-offset-4">
                      <h1>  {_('Forgot Password Step 1')}</h1>

                      <label class="form-label  form-required">
                        {_('Please enter your @royallepage.ca email address')}
                      </label>

                      <div class="form-field form-input mb2">
                        <input
                          for="terminating-username"
                          type="text"
                          id="fpEmailAddress"
                          name="fpEmailAddress"
                          class="form-control"
                          maxlength="128"
                          style={{ 'max-width': '541px' }}
                        />
                      </div>
                      <div
                        style={{
                          display: state.forgotPasswordStep1 && state.noEmailAddressProvided ? 'block' : 'none',
                          'padding-right': '17px',
                        }}>
                        <p>  {_('Email Address/User Name not provided')}</p>
                      </div>
                      <p>
                        {' '}
                        {_(
                          'If you do not know your Royal LePage email address, please contact your office or Customer Care to confirm your email address. If you need assistance resetting your password, please contact Customer Care. You can reach us by emailing help@royallepage.ca or calling us at 1-877-757-4545.'
                        )}
                      </p>
                      <div class="row mt3">
                        <div class="col-md-6 mb2 pull-right-md">
                          <a
                            href=""
                            onclick={() => this.run('retrieveRecoveryEmail', $('#fpEmailAddress').val())}
                            class="btn btn-rlp btn-block text-uppercase">
                            <i class="fas fa-check-circle mr1" />
                            {_('Continue')}
                          </a>
                        </div>
                        <div class="col-md-6 pull-right-md">
                          <a
                            href=""
                            onclick={e => this.run('toggleFlag', 'state.forgotPasswordStep1', e)}
                            class="btn btn-rlp btn-rlp-inverse btn-block text-uppercase">
                            <i class="fas fa-times-circle text-uppercase mr1" />
                            {_('Cancel')}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal in"
          id="forgotPasswordStep2"
          tabindex="-1"
          role="dialog"
          style={{ display: state.forgotPasswordStep2 ? 'block' : 'none', 'padding-right': '17px' }}>
          <div class="modal-dialog" role="document">
            <div class="modal-content" style={{ height: 'auto' }}>
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">
                    <h1 class="m0" onclick={e => this.run('toggleFlag', 'state.forgotPasswordStep2', e)}>
                      ×
                    </h1>
                  </span>
                </button>
              </div>
              <div class="modal-body modal-body-lightbox">
                {state.validStep2 && (
                  <div class="container" id="divValidStep2">
                    <div class="row">
                      <div class="col-sm-16 col-sm-offset-4">
                        <h1>  {_('Forgot Password Step 2')}</h1>
                        <label class="form-label  form-required mt3">
                          {_('Would you like password reset instructions sent to the email address below?')}
                        </label>
                        <div class="form-field form-input">{state.maskedRecoveryEmail}</div>
                        <p class="mt2">
                          {_(
                            'If you no longer have access to the recovery email on file, you will need to contact Customer Care. You can reach us at help@royallepage.ca or call 1-877-757-4545.'
                          )}
                        </p>
                        <div class="row mt3">
                          <div class="col-md-6 mb2 pull-right-md">
                            <a
                              href=""
                              onclick={() => this.run('sendFPInstructions')}
                              class="btn btn-rlp btn-block text-uppercase">
                              <i class="fas fa-check-circle mr1" />
                              {_('Send')}
                            </a>
                          </div>
                          <div class="col-md-6 pull-right-md">
                            <a
                              href=""
                              onclick={e => this.run('toggleFlag', 'state.forgotPasswordStep2', e)}
                              class="btn btn-rlp btn-rlp-inverse btn-block text-uppercase">
                              <i class="fas fa-times-circle text-uppercase mr1" />
                              {_('Cancel')}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!state.validStep2 && (
                  <div class="container" id="divValidStep2">
                    <div class="row">
                      <div class="col-sm-16 col-sm-offset-4">
                        <h1>  {_('Forgot Password Step 2')}</h1>
                        <p>
                          {_(
                            'You do not have a recovery email on file. Please contact Customer Care. You can reach us at help@royallepage.ca or call 1-877-757-4545.'
                          )}
                        </p>
                        <div class="row mt3">
                          <div class="col-md-6 mb2 pull-right-md">
                            <a
                              href=""
                              onclick={e => this.run('toggleFlag', 'state.forgotPasswordStep2', e)}
                              class="btn btn-rlp btn-block text-uppercase">
                              <i class="fas fa-times-circle text-uppercase mr1" />
                              {_('Cancel')}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal in"
          id="forgotPasswordStep3"
          tabindex="-1"
          role="dialog"
          style={{ display: state.forgotPasswordStep3 ? 'block' : 'none', 'padding-right': '17px' }}>
          <div class="modal-dialog" role="document">
            <div class="modal-content" style={{ height: 'auto' }}>
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">
                    <h1 class="m0" onclick={e => this.run('toggleFlag', 'state.forgotPasswordStep3', e)}>
                      ×
                    </h1>
                  </span>
                </button>
              </div>
              <div class="modal-body modal-body-lightbox">
                <div class="container">
                  <div class="row">
                    <div class="col-sm-16 col-sm-offset-4">
                      <h1>  {_('Forgot Password Step 3')}</h1>
                      <p>
                        {_('Password reset instructions has been sent to ')} {state.maskedRecoveryEmail}.
                      </p>
                      <p>
                        {_(
                          'Remember: Changing your password here will require you to change your email password on all of your devices.'
                        )}
                      </p>
                      <div class="row mt3">
                        <div class="col-md-8 mb2 pull-right-md">
                          <a
                            href=""
                            onclick={e => this.run('toggleFlag', 'state.forgotPasswordStep3', e)}
                            class="btn btn-rlp btn-block text-uppercase">
                            <i class="far fa-arrow-alt-circle-right text-uppercase mr1" />
                            {_('Go to Login Page')}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {state.showResetPasswordView ? (
          <ResetPasswordView state={state} mainComponent={this} />
        ) : (
          <LoginView state={state} mainComponent={this} textMessage={textMessage} />
        )}

        <div class="footer">
          <div class="container" />
          <div
            class="modal-backdrop in"
            style={{
              display:
                state.lightBoxValue ||
                  state.forgotPasswordStep1 ||
                  state.forgotPasswordStep2 ||
                  state.forgotPasswordStep3
                  ? 'block'
                  : 'none',
            }}
          />
          <div class="section section-secondary-color-left section-no-padding">
            <div class="container">
              <div class="row">
                <div class="col-sm-6 col-md-4">{logoImageFooter}</div>
                <div class="col-sm-18 col-md-20" style={{ 'background-color': '#3a3a3a' }}>
                  <div class="row">
                    <div class="col-md-16">
                      <div class="ml2 my3 pt1">
                        <a href="http://royallepage.ca" class="light mr1">
                          royallepage.ca
                        </a>
                        <span class="red">|</span>
                        <a href="http://royallepagecommercial.com" class="light ml1">
                          royallepagecommercial.com
                        </a>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="footer-brand pull-right-lg">
                        <nav class="navbar navbar-footer-nav">
                          <ul class="nav navbar-nav">
                            <li>
                              <a href="https://www.facebook.com/royallepage" target="_blank" class="social">
                                <span class="sr-only">Facebook</span>
                                <i class="fab fa-facebook-f" />
                              </a>
                            </li>
                            <li>
                              <a href="https://www.linkedin.com/company/royal-lepage" target="_blank" class="social">
                                <span class="sr-only">LinkedIn</span>
                                <i class="fab fa-linkedin" />
                              </a>
                            </li>
                            <li>
                              <a href="https://www.youtube.com/user/RoyalLePageCanada" target="_blank" class="social">
                                <span class="sr-only">YouTube</span>
                                <i class="fab fa-youtube" />
                              </a>
                            </li>
                            <li>
                              <a href="http://instagram.com/royal_lepage" target="_blank" class="social">
                                <span class="sr-only">Instagram</span>
                                <i class="fab fa-instagram" />
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-16">
                      <div class="ml2 my3 pt1 light mr1" style="color:white;">
                        {'_html:' + _('Royal LePage® is a registered trademark of Royal Bank of Canada and is used under licence by Bridgemarq Real Estate Services®. View important disclosures and notices about trademarks at <a href="http://rlp.ca/notices" target="_blank" style="color:#ee3e42">rlp.ca/notices</a>.')}
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="footer-brand pull-right-lg">
                        {state.lang === 1 ? <a href="http://www.royallepage.ca/fr"><img
                          src="/wp-content/themes/rlp/img/logo-royal-lepage-fr-v1.png"
                          class="inline-block"
                          alt="Royal LePage"
                        /></a> : <a href="http://www.royallepage.ca/en"><img
                          src="/wp-content/themes/rlp/img/logo-royal-lepage-v1.png"
                          class="inline-block"
                          alt="Royal LePage"
                        /></a>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>
    );
  };

  update = {
    lightBoxValue: (state: IState) => {
      if (state.lightBoxValue) {
        state.lightBoxValue = false;
        //console.log("this value is false and setting it to false")
      } else {
        state.lightBoxValue = true;
        //console.log("this value is true and setting it to true")
      }
      return state;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toggleFlag: (state: IState, fieldToCheck, e: any = {}) => {
      e.preventDefault();
      const exp = fieldToCheck + ' = !' + fieldToCheck; //toggle the value
      // //console.log(exp);
      eval(exp);
      return state;
    },
    retrieveRecoveryEmail: async (state: IState, fpEmailAddress) => {
      //console.log(fpEmailAddress);
      //get recovery email
      if (!fpEmailAddress) {
        state.forgotPasswordStep2 = false;
        state.forgotPasswordStep1 = true;
        state.forgotPasswordStep3 = false;
        state.noEmailAddressProvided = true;
      } else {
        state.noEmailAddressProvided = false;
        const user = await users.getRecoveryEmail(fpEmailAddress);
        if (user) {
          //mask part of email
          if (user.recoveryEmail) {
            state.recoveryEmail = user.recoveryEmail;
            state.maskedRecoveryEmail = this.maskEmailAddress(user.recoveryEmail);
            state.validStep2 = true;
          } else {
            state.validStep2 = false;
          }
          //console.log(state.recoveryEmail);
        } else {
          state.validStep2 = false;
        }

        state.forgotPasswordStep2 = true;
        state.forgotPasswordStep1 = false;
        state.forgotPasswordStep3 = false;
      }
      return state;
    },
    sendFPInstructions: async (state: IState) => {
      //TODO : send to Notification service
      const sendResult = await users.sendForgotPasswordInstructions(state.recoveryEmail);

      //let sendResult = true;
      if (sendResult) {
        state.forgotPasswordStep3 = true;
        state.forgotPasswordStep1 = false;
        state.forgotPasswordStep2 = false;
      }

      return state;
    },
    validatePassword: (state, e) => {
      const isValid = this.validPassword(e.target.value);
      if (!isValid) {
        state.error.msg = 'Invalid Password';
        state.error.detail = 'Invalid Password';
      } else {
        state.resetPasswordForm.password = e.target.value;
        state.error.msg = '';
        state.error.detail = '';
      }
      return state;
    },

    changePassword: async (state: IState, password, confirmPassword) => {
      if(!this.validPassword(password)) {
        //invalid password
        state.error.msg = 'Invalid password';
        state.error.detail = 'Invalid password';
      } else if (password !== confirmPassword) {
        state.error.msg = 'Password and Confirm Password do not match';
        state.error.detail = 'Password and Confirm Password do not match';
      } else {
        state.resetPasswordForm.password = password;
        state.resetPasswordForm.confirmPassword = confirmPassword;
        //send to backend to process
        const result = await users.resetPassword(state.resetPasswordForm);
        if (result === 0) {
          state.error.msg = 'Error resetting password';
          state.error.detail = 'Error resetting password';
        } else if (result === 2) {
          //invalid password
          state.error.msg = 'Invalid password';
          state.error.detail = 'Invalid password';
        } else {
          //TODO : auto sign-in
          state.error.msg = '';
          state.error.detail = '';
          state.passwordChanged = true;
        }
      }

      return state;
    },

    '#resetPassword': (state, emailAddress, hashCode) => {
      const params = new URLSearchParams(location.search);
      const language = params.get('lang')?.trim().replace('/', '') || '';

      if (language === '1') {
        state.lang = 1;
        setLanguage(Language[state.lang]);
      } else {
        state.lang = 0;
        setLanguage(Language[state.lang]);
      }

      state.resetPasswordForm.emailAddress = emailAddress;
      state.resetPasswordForm.hashCode = hashCode;
      // //console.log(emailAddress);
      // //console.log(hashCode);
      state.showResetPasswordView = true;
      return state;
    },
    '#logout': () => {
      auth.signOut();
      // document.location.href = '/wp-login.php?action=logout';
      document.location.href = '/login';
    },
    'sign-in-loading': (state) => {
      state.isLoading = true;
      state.error.msg = '';
      return state;
    },
    'sign-in': async (state, e) => {
      state.isLoading = false;

      try {
        e.preventDefault();

        try {
          const singInRetVal = await auth.signIn(serializeObject(e.target) as { email: string; password: string });
          if (singInRetVal === 'stop') { return; }
        } catch (errors) {
          const error = { msg: 'Login failed', detail: 'Invalid username or password' };
          return { ...state, error };
        }
        // this.saveToken(session);
        const urlParams = new URLSearchParams(location.search);

        state.redirectUrl = urlParams.get('redirectUrl') || '';
        if (state.redirectUrl) {
          const cleaned = decodeURIComponent(urlParams.toString()).replace('redirectUrl=' + state.redirectUrl, '');
          state.redirectUrl = state.redirectUrl + (state.redirectUrl.includes('?') ? '&' : '?') + cleaned;
          state.redirectUrl = state.redirectUrl.replace('&&', '&');

          window.location.replace(state.redirectUrl);
        } else {
          if (getCookie('pll_language') === 'fr') { window.location.replace('/accueil'); } else { window.location.replace('/'); }
        }
      } catch ({ error }) {
        return { ...state, error };
      }
    },
    'resetpassword-sign-in': async (state: IState, e) => {
      state.isLoading = false;
      try {
        e.preventDefault();

        try {
          const payload = { email: state.resetPasswordForm.emailAddress, password: state.resetPasswordForm.password };
          await auth.signIn(payload);
        } catch (errors) {
          const error = { msg: 'Login failed', detail: 'Invalid username or password' };
          return { ...state, error };
        }
        // this.saveToken(session);

        if (getCookie('pll_language') === 'fr') { window.location.replace('/accueil'); } else { window.location.replace('/'); }
      } catch ({ error }) {
        return { ...state, error };
      }
    },

    // '#user': (state, user) => {
    //   setToken(user ? user.token : null);
    //   app['user'] = user;
    // },

    '#set-language': (state) => {
      state.lang = state.lang === Language.en ? Language.fr : Language.en; // 0,1 used by WordPress
      const _lang = Language[state.lang];
      setLanguage(_lang);
      return state;
    },

    'new-pwd-changed':() => {
      // Get the current value of the password input
      const passwordInput = document.getElementById('password') as HTMLInputElement;
      const password = passwordInput.value;
      // Rule 1: Password must have at least 8 characters and it should not exceed 40 characters
      const rule1 = (password.length >= 8) && (password.length <= 40);
      // Rule 2: Password must contain at least one uppercase letter
      const rule2 = /[A-Z]/.test(password);
      // Rule 3: Password must contain at least one lowercase letter
      const rule3 = /[a-z]/.test(password);
      // Rule 4: Password must contain at least one digit
      const rule4 = /\d/.test(password);
      // Rule 5: Password must contain at least one special character
      const rule5 = /[!@#$%^&*()\-_=+{}[\]|\\;:'",.<>/?`~]/.test(password);
      // Rule 5: Password should match with confirm
      const rule6 = (password === (document.getElementById('confirmPassword') as HTMLInputElement).value);

      this.setPwdRruleStatus(rule1, 'rule1');
      this.setPwdRruleStatus(rule2, 'rule2');
      this.setPwdRruleStatus(rule3, 'rule3');
      this.setPwdRruleStatus(rule4, 'rule4');
      this.setPwdRruleStatus(rule5, 'rule5');
      this.setPwdRruleStatus(rule6, 'rule6');
    },

    // '#refresh-JWT': state => {
    //   return;
    // }
  };

  setPwdRruleStatus = (rule, id) => {
    const el = document.getElementById(id);
    el.classList.remove((rule) ? 'text-danger' : 'text-success');
    el.classList.add((rule) ? 'text-success' : 'text-danger');

    const elIcon = document.getElementById(id+'Icon');
    elIcon.classList.remove((rule) ? 'fa-close' : 'fa-check');
    elIcon.classList.add((rule) ? 'fa-check' : 'fa-close');
    elIcon.style.color = (rule) ? 'green' : 'red';
  }

  // private saveToken(session) {
  //   const { user } = session;
  //   setToken(user ? user.token : null);
  //   // try {
  //   //   session = JSON.parse(session);
  //   //   app.run('#user', session.user);
  //   // } catch {}
  // }

  private maskEmailAddress(input) {
    let result = '';
    let maskUsername = '';
    const prefix = input.substring(0, input.lastIndexOf('@'));
    const postfix = input.substring(input.lastIndexOf('@'));
    for (let i = 0; i < prefix.length; i++) {
      if (i === 0 || i === prefix.length - 1) {
        maskUsername = maskUsername + prefix[i].toString();
      } else {
        maskUsername = maskUsername + '*';
      }
    }
    result = maskUsername + postfix;
    return result;
  }

  // rendered = (state) => {
  //   let token = await authorization.loginWithJWT();
  //   if (token) {
  //     let email = AccessToken.getUserFromJWT().sub;
  //     let session = {
  //       user: {
  //         token,
  //         email,
  //       },
  //     };
  //     this.saveToken(state, session);
  //   }
  // };

  private validPassword(password) {
    // const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,12}$/;
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?/~'";|=)]).{8,40}$/;
    return re.test(password) && !password.includes('royal1');
  }
}

new LoginComponent().start('login-page');
