const DEFAULT_LANGUAGE = 'en';
import dict from './dict.json';

export let lang = document.cookie.replace(/(?:(?:^|.*;\s*)pll_language\s*=\s*([^;]*).*$)|^.*$/i, '$1') || 'en';

export const setLanguage = (value, name = 'pll_language') => {
  lang = value;
  document.cookie = `${name}=${value || ''};path=/`;
};

export default (text, ...args) => {
  let txt = (lang && dict[lang] && dict[lang][text]) || (lang === DEFAULT_LANGUAGE ? text: `${text}*`);

  let n = 0;
  while (txt.indexOf('%') > 0) {
    txt = txt.replace('%', args[n++] || '');
  }

  return txt;
};

export const __ = (text) => {
  const txt = (lang && dict[lang] && dict[lang][text]) || (lang === DEFAULT_LANGUAGE ? text: `${text}*`);
  return txt;
};